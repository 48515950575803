import React from 'react';
import SEO from '../components/SEO';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Contact = props => (
  <Layout bodyClass="page-contact contact-page">
    <SEO 
      title="Artists' Choice - Custom Framing Inquiry Form"
      description="We Offer Custom Frames In-Person, Online, or Over The Phone!"
      />
    <div className="container has-text-centered">
    <div className="container pt-4 pb-4"><h1>Time To Get Something Framed?</h1></div>
    <p>We've got you covered!</p>
    <p>Below is our form that will get you started. After some general information is sent over we will contact you directly. We appreciate you trusting us with your framing needs!</p>
        <div className="form-block">
          <ReactTypeformEmbed 
              url="https://demo.typeform.com/to/fXyayd"
              buttonText= "Click Here To Contact By Email"
              mode= "drawer_left"
          />
        </div>
 
    </div>
  </Layout>
);

export default Contact;
